import React, {Fragment, useState, useEffect, useContext} from "react";
import ReactDOM from "react-dom";
import "./index.scss";

import { BrowserRouter, Switch, Route } from "react-router-dom";
import ErrorBoundary from "./errorHandler";

import { Provider } from "react-redux";
import Decharge from "./components/decharge/decharge";

import store from "./store";
import { setAuthToken } from "./utils/backend.instance";
import Fournisseur from "./components/home/fournisseur";
import Exportfournisseur from "./components/Fournisseurs/exportfournisseur"
import Exportfinance from "./components/finance/exportfinance";
import Download from "./components/colis/download";
import Downloadcsv from "./components/colis/downloadcsv";
import Printdecharge from "./components/decharge/printdecharge";
import DechargeP from "./components/decharge/dechargeP";
import Financeexcel from "./components/finance/financeexcel";
import CSV from "./components/colis/csv";

// Signin page.jsx
import Signin from "./auth/signin";

// Authentication

import PrivateRoute from "./routing/privateroute";

// Error page.jsx
import Error400 from "./pages/errors/error400";
import Error401 from "./pages/errors/error401";
import Error403 from "./pages/errors/error403";
import Error404 from "./pages/errors/error404";
import Error500 from "./pages/errors/error500";
import Error503 from "./pages/errors/error503";

// Maintenance
import Maintenance from "./pages/maintenance";
import Landing from "./components/home/Landing";
import Birthday from "./components/home/Birthday";
import Service from "./components/home/services";
import About from "./components/home/about";
import Partner from "./components/home/partner";
import Main from "./Main";
import AppContext from "./context/Context";


const Root = () => {
    const {
        config: {isAuthenticated, access},
    } = useContext(AppContext);
  const [loggedIn, setLoggedIn] = useState(null);
  useEffect(() => {
    setLoggedIn(Boolean(isAuthenticated));
    setAuthToken(access);
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // useEffect(() => {
  //   // check for token in LS when app first runs
  //   if (localStorage.access) {
  //     // if there is a token set axios headers for all requests
  //     setAuthToken(localStorage.access);
  //   }
  //   // try to fetch a user, if no token or invalid token we
  //   // will get a 401 response from our API
  //   // if (localStorage.refresh) {
  //   //   store.dispatch(loadUser());
  //   // }
  //   // store.dispatch(getReclamations());
  //   // log user out from all tabs if they log out in one tab
  //   window.addEventListener("storage", () => {
  //     if (!localStorage.refresh) {
  //       store.dispatch({ type: LOGOUT });
  //     }
  //   });
  // }, []);
  return (
    <Fragment>
      <Provider store={store}>
        {loggedIn !== null && (
          <BrowserRouter basename={`/`}>
            <ErrorBoundary>
              <Switch>
                <Route exact path={`/`} component={Birthday} />
                {/* <Route exact path={`/`} component={Landing} /> */}
                <Route exact path={`/birthday`} component={Birthday} />
                <Route path={"/services"} component={Service} />
                <Route path={"/about"} component={About} />
                <Route path={"/partner"} component={Partner} />
                <Route path={"/fournisseurs"} component={Fournisseur} />
                <Route
                  path={`/signin`}
                  component={Signin}
                  loggedIn={loggedIn}
                ></Route>
                <Route
                  path={`/pages/errors/error400`}
                  component={Error400}
                ></Route>
                <Route
                  path={`/pages/errors/error401`}
                  component={Error401}
                ></Route>
                <Route
                  path={`/pages/errors/error403`}
                  component={Error403}
                ></Route>
                <Route
                  path={`/pages/errors/error404`}
                  component={Error404}
                ></Route>
                <Route
                  path={`/pages/errors/error500`}
                  component={Error500}
                ></Route>
                <Route
                  path={`/pages/errors/error503`}
                  component={Error503}
                ></Route>
                <Route path={`/colis/print/`} component={Printdecharge}></Route>
                <Route path={`/colis/decharge/`} component={Decharge}></Route>
                <Route path={`/colis/excel`} component={Download}></Route>
                <Route path={`/finance/excel`} component={Financeexcel}></Route>
                <Route path={`/colis/csv`} component={Downloadcsv}></Route>
                <Route path={`/colis/export`} component={CSV}></Route>
                <Route path={`/fournisseur/export`} component={Exportfournisseur}></Route>
                <Route path={`/finance/export`} component={Exportfinance}></Route>
                <Route path={`/colis/printp/`} component={DechargeP}></Route>

                <Route
                  path={`/pages/maintenance`}
                  component={Maintenance}
                ></Route>
                <PrivateRoute />
              </Switch>
            </ErrorBoundary>
          </BrowserRouter>
        )}
      </Provider>
    </Fragment>
  );
};
ReactDOM.render(
  <ErrorBoundary>
    <Main>
      <Root />
    </Main>
  </ErrorBoundary>,
  document.getElementById("root")
);
